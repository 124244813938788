<script>
import MixedGrid from '@phoenix/mixins/components/mixedGrid/MixedGrid';
import MixedGridFilters from './MixedGridFilters';
import Product from '@maison/components/common/Product';
import ProductPush from '@maison/components/product/ProductPush';
import MixedGridPagePushItem from '@maison/components/mixedGrid/MixedGridPagePushItem';
import { map, forEach } from 'lodash-es';
import { FEATURE_FLAG, getFeatureFlag } from '@phoenix/helpers/feature-flag-helper';

export default {
  components: {
    MixedGridFilters,
    Product,
    ProductPush,
    MixedGridPagePushItem,
  },

  mixins: [MixedGrid],

  props: {
    alignedCenter: {
      type: Boolean,
      default: false,
    },
    headingTag: {
      default: 'h2',
      type: String,
    },
    // Only required for the MixedGridFilters
    noveltyFilterId: {
      default: '',
      type: String,
    },
    noveltyProductPropertyValueIds: {
      default: () => [],
      type: Array,
    },

    shouldDisplayAvailableOnline: {
      type: Boolean,
      default: true,
    },
    allSortedItems: {
      type: Array,
      required: true,
    },
  },

  computed: {
    gridClasses() {
      return ['mixed-grid', { 'mt-base md:mt-base lg:mt-lg mb-base': this.hasHeadings && !this.displayFilter }];
    },

    hasHeadings() {
      return this.title || this.subtitle;
    },

    loadMoreClasses() {
      return [
        'actions actions--center mt-md',
        map(this.maxPage, (page, size) => {
          const prefix = size !== 'xs' ? `${size}:` : '';
          return this.page >= page ? `${prefix}hidden` : `${prefix}block`;
        }),
      ];
    },

    shouldDisplaySingleProduct() {
      return !this.displayFilter && this.columns.length === 1 && this.columns[0].template === 'product';
    },

    shouldDisplaySellable() {
      return getFeatureFlag(FEATURE_FLAG.ECOM) || getFeatureFlag(FEATURE_FLAG.LIGHT_ECOM);
    },

    subHeadingTag() {
      return this.headingTag === 'h1' ? 'h2' : 'h3';
    },

    widths() {
      return {
        lg: {
          1: '1/3',
          2: '2/3',
          3: '1/1',
        },
        md: {
          1: '1/3',
          2: '2/3',
          3: '1/1',
        },
        sm: {
          1: '1/2',
          2: '1/1',
        },
        xs: {
          1: '1/2',
          2: '1/1',
        },
      };
    },
  },

  methods: {
    getColumnDisplayClasses(column) {
      if (!this.shouldDisplayLoadMore) {
        return [];
      }

      return map(column.page, (page, size) => {
        const prefix = size !== 'xs' ? `${size}:` : '';
        return this.page < page ? `${prefix}hidden` : '';
      });
    },

    getColumnClasses(column) {
      return ['mixed-grid__item', ...this.getColumnDisplayClasses(column)];
    },

    getColumnComponent(template) {
      switch (template) {
        case 'product':
          return Product;
        case 'push-item':
          return MixedGridPagePushItem;
      }
    },

    getColumnPage(columnCount, itemsPerPage, forceEvenItemsPerPage) {
      if (forceEvenItemsPerPage && itemsPerPage % 2 !== 0) {
        itemsPerPage += 1;
      }
      return Math.ceil(columnCount / itemsPerPage);
    },

    /**
     * Each maison has a particular paging logic according to the amount of columns per row, per breakpoint.
     * This logic suffices while we're displaying products, but it must be revised once
     * flexible size media items are added to the mixed grid block.
     */
    initColumns() {
      this.columns = this.allSortedItems.map((item, index) => {
        const columnCount = index + 1;
        const columnPagePerSize = {};
        const mdColumnPage = this.getColumnPage(columnCount, this.itemsPerPage, false);
        const columnPage = this.getColumnPage(columnCount, this.itemsPerPage, true);

        forEach(Object.keys(this.widths), (size) => {
          columnPagePerSize[size] = ['md', 'lg'].indexOf(size) > -1 ? mdColumnPage : columnPage;
        });

        if (columnCount === this.products.length) {
          this.maxPage = { ...columnPagePerSize };
        }

        if (item.template === 'product') {
          return {
            data: item,
            template: 'product',
            page: columnPagePerSize,
            size: 1,
          };
        }

        if (item.template === 'push-item') {
          item.page = columnPagePerSize;
          return item;
        }
      });
    },
  },
};
</script>

<template>
  <div class="container" data-cy="mixed-grid">
    <div v-if="hasHeadings" :class="['headings', { 'headings--center': alignedCenter }]">
      <component :is="headingTag" v-if="title" class="h3">{{ title }}</component>
      <component :is="subHeadingTag" v-if="subtitle" class="headings__subtitle h6">{{ subtitle }}</component>
    </div>
    <product-push v-if="shouldDisplaySingleProduct" v-bind="columns[0].data" :add-margin-top="hasHeadings" />
    <template v-else>
      <mixed-grid-filters
        v-if="displayFilter"
        :filters="filtersWithLocalizedPrices"
        :products-count="filteredColumnsCount"
        :unique-id="uniqueId"
        :default-sort="defaultSort"
        :default-filters="activeFilters"
        :novelty-filter-id="noveltyFilterId"
        :novelty-product-property-value-ids="noveltyProductPropertyValueIds"
        :should-display-sellable="shouldDisplaySellable"
        :should-display-available-online="shouldDisplayAvailableOnline"
        :has-filters-in-url="hasFiltersInUrl"
        @on-sort-change="onSortChange"
        @on-filters-changed="onFiltersChanged"
      />
      <div :class="gridClasses">
        <div v-for="column in filteredColumns" :key="column.data.itemId" :class="getColumnClasses(column)">
          <component
            :is="getColumnComponent(column.template)"
            v-bind="column.data"
            :data-tracking="column.template === 'product' ? getDataTracking(column.data) : null"
            data-cy="mixed-grid-item"
            :data-cy-item="column.template"
          >
          </component>
        </div>
      </div>
      <ul v-if="shouldDisplayLoadMore" :class="loadMoreClasses" role="presentation">
        <li role="presentation">
          <button class="btn btn--secondary actions--center mt-md" data-cy="mixed-grid-see-all" @click="seeAll">
            <span class="btn__text">{{ $t('Button.seeAll', { nb: nbOfMaxItems }) }}</span>
          </button>
        </li>
      </ul>
    </template>
  </div>
</template>
