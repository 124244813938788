<script>
import MixedGridSortSelect from '@phoenix/mixins/components/mixedGrid/MixedGridSortSelect';

export default {
  mixins: [MixedGridSortSelect],
};
</script>

<template>
  <div class="input-wrapper input-wrapper-select" data-cy="mixed-grid-sort-select">
    <select
      id="order"
      v-model="localValue"
      name="order"
      class="input input--select filters__sort-by btn btn--secondary"
    >
      <option v-for="(option, val) in sortOptions" :key="option.id" :value="val">
        {{ $t(option.label) }}
      </option>
    </select>
  </div>
</template>
