<script>
import MixedGridFiltersItemRange from '@phoenix/mixins/components/mixedGrid/MixedGridFiltersItemRange';

export default {
  mixins: [MixedGridFiltersItemRange],

  props: {
    centered: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rangeClasses() {
      return ['range-slider', { 'range-slider--centered': this.centered }];
    },
  },
};
</script>

<template>
  <div :class="rangeClasses" data-cy="mixed-grid-filter-range">
    <div
      :id="uniqueId"
      ref="range"
      class="range"
      :data-property="filter.id"
      :data-label="filter.label"
      :data-options="filter.options"
      :data-items="filter.items"
      :disabled="filter.disabled"
    ></div>
  </div>
</template>
