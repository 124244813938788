<script>
import { filter, has } from 'lodash-es';
import MixedGridFilters from '@phoenix/mixins/components/mixedGrid/MixedGridFilters';
import MixedGridSortSelect from './MixedGridSortSelect';
import MixedGridFiltersItemList from './MixedGridFiltersItemList';
import MixedGridFiltersItemRange from './MixedGridFiltersItemRange';
import MixedGridFiltersSelected from './MixedGridFiltersSelected';
import Panel from '../common/Panel';
import { closePanel, showPanel } from '@phoenix/helpers/panel-helper';

export default {
  components: {
    MixedGridSortSelect,
    MixedGridFiltersItemList,
    MixedGridFiltersItemRange,
    MixedGridFiltersSelected,
    Panel,
  },

  mixins: [MixedGridFilters],

  props: {
    id: {
      default: 'mixed-grid-filters',
      type: String,
    },
    noveltyFilterId: {
      required: true,
      type: String,
    },
    noveltyProductPropertyValueIds: {
      required: true,
      type: Array,
    },
    shouldDisplayAvailableOnline: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    panelFilters() {
      return filter(this.filters, (filter) => {
        if (filter.id === this.noveltyFilterId) {
          return false;
        }

        if (filter.component === 'range') {
          return true;
        }

        return filter.items && filter.items.length > 0;
      });
    },

    panelId() {
      return `${this.id}-panel`;
    },

    productCountLabel() {
      return this.productsCount > 0
        ? `${this.productsCount} ${this.$t('Products.filters.resultsNoun')}`
        : this.$t('Products.filters.noResults');
    },

    shouldDisplayNoveltyFilter() {
      return this.noveltyProductPropertyValueIds.length > 0;
    },

    novelty: {
      get() {
        return has(this.activeFilters, this.noveltyFilterId);
      },
      set(value) {
        this.setCheckedFilter(value, this.noveltyFilterId, this.noveltyProductPropertyValueIds);
      },
    },
  },

  methods: {
    closePanel,

    showPanel,
  },
};
</script>

<template>
  <div class="filters mt-base md:mt-base lg:mt-lg mb-base">
    <div class="filters__bar">
      <div class="filters__actions">
        <button type="button" class="btn btn--secondary" @click.prevent="showPanel(panelId)">
          <span class="btn__text">{{ $t('Product.filters') }}</span>
        </button>
        <mixed-grid-sort-select v-model="currentSort"></mixed-grid-sort-select>
      </div>
      <div class="filters__quick-actions">
        <div v-if="shouldDisplaySellable && shouldDisplayAvailableOnline" class="switch">
          <input
            id="available-online"
            v-model="availableOnline"
            type="checkbox"
            class="switch__input"
            data-cy="mixed-grid-available-online"
          />
          <label id="available-online-label" for="available-online" class="input-label switch__label checkbox-label">
            {{ $t('Products.filters.availableOnline') }}
          </label>
        </div>
        <div v-if="shouldDisplayNoveltyFilter" class="switch">
          <input id="novelty" v-model="novelty" type="checkbox" class="switch__input" data-cy="mixed-grid-novelty" />
          <label id="novelty-label" for="novelty" class="input-label switch__label checkbox-label">
            {{ $t('Products.filters.novelties') }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <mixed-grid-filters-selected
    :filters="filters"
    :active-filters="activeFilters"
    :novelty-filter-id="noveltyFilterId"
    @remove-filter="removeFilter"
    @remove-all-filters="removeAllFilters"
  />
  <div class="filters__result-count" data-cy="mixed-grid-products-count">{{ productCountLabel }}</div>

  <teleport to="#teleport-target-panels">
    <panel
      :id="panelId"
      :classes="['products-filters-panel']"
      :title="$t('Product.filters')"
      data-cy="mixed-grid-filters"
    >
      <template #content>
        <template v-for="(filter, key, index) in panelFilters" :key="`filter-${filter.id}`">
          <h6 class="products-filters-panel__title">{{ filter.label }}</h6>
          <mixed-grid-filters-item-list
            v-if="filter.component === 'list'"
            :filter="filter"
            :active-filters="activeFilters"
            @on-item-selected="toggleFilter"
          />
          <mixed-grid-filters-item-range
            v-if="filter.component === 'range'"
            centered
            :filter="filter"
            :symbol="getSymbolFromFilter(filter)"
            :active-filters="activeFilters"
            @on-range-change="onRangeChange"
          />
          <hr v-if="index < Object.keys(filters).length - 1" class="divider" />
        </template>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn--full btn--negative"
          :data-tracking="datalayerTrackingViewResults"
          @click.prevent="closePanel(panelId)"
        >
          <span class="btn__text">{{ $t('Button.showResults') }}</span>
        </button>
      </template>
    </panel>
  </teleport>
</template>
