<script>
import Product from '@phoenix/mixins/components/common/Product';
import ProductCardTags from '@maison/components/product/ProductCardTags';
import PictureLazy from '@maison/components/common/PictureLazy';

export default {
  components: {
    ProductCardTags,
    PictureLazy,
  },

  mixins: [Product],

  props: {
    addMarginTop: {
      default: false,
      type: Boolean,
    },
    ctaText: {
      default: null,
      type: String,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    labelsNamedPagesUrls: {
      type: Object,
      default: () => ({}),
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    referenceText: {
      type: String,
      default: '',
    },
    imageSinglePush: {
      type: Object,
      required: true,
    },
    isDiscontinuedReference: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClasses() {
      return ['single-push', { 'mt-base md:mt-base lg:mt-lg mb-base': this.addMarginTop }];
    },
  },
};
</script>

<template>
  <div :class="containerClasses" data-cy="single-push">
    <div class="single-push__column">
      <a :href="url" class="picture single-push__image">
        <picture-lazy :lazy="lazy" v-bind="imageSinglePush" style="pointer-events: inherit" />
      </a>
    </div>
    <div class="single-push__column">
      <product-card-tags :labels="labels" :labels-named-pages-urls="labelsNamedPagesUrls" />
      <div class="headings">
        <h2 class="h3">{{ name }}</h2>
        <h3 class="headings__subtitle h6" v-html="referenceText" />
      </div>
      <div class="single-push__description h4">{{ description }}</div>
      <div class="single-push__infos">
        <template v-if="isDiscontinuedReference">
          <span class="product-price__value">{{ $t('Product.discontinuedReference.label') }}</span>
        </template>
        <template v-else>
          <product-price v-bind="localizedPrice" :should-display-from-price="shouldDisplayFromPrice" />
          <span v-if="shouldDisplayVatInformation">{{ vatInformation }}</span>
        </template>
      </div>
      <a :href="url" class="btn btn--secondary single-push__cta">
        <span class="btn__text">{{ ctaText ? ctaText : $t('CTA.singleProductPush') }}</span>
      </a>
    </div>
  </div>
</template>
