<script>
import MixedGridFiltersSelected from '@phoenix/mixins/components/mixedGrid/MixedGridFiltersSelected';
import MixedGridActiveFilterTag from './MixedGridActiveFilterTag';

export default {
  components: {
    MixedGridActiveFilterTag,
  },

  mixins: [MixedGridFiltersSelected],

  props: {
    itemClass: {
      default: 'filters__active-filter',
      required: false,
      type: String,
    },
    noveltyFilterId: {
      required: true,
      type: String,
    },
  },

  methods: {
    isNovelty(filterId) {
      return filterId === this.noveltyFilterId;
    },
  },
};
</script>

<template>
  <ul v-if="hasFilters" class="filters__active-filters" data-cy="mixed-grid-filters-selected">
    <template v-for="(groupedFilters, filterId) in activeFilters">
      <template v-if="isSellable(filterId)">
        <li :key="filterId" :class="itemClass" data-cy="mixed-grid-filters-selected-choice">
          <mixed-grid-active-filter-tag
            :filter-id="filterId"
            :label="$t('Products.filters.availableOnline')"
            @click.stop="$emit('removeFilter', filterId)"
          />
        </li>
      </template>
      <template v-else-if="isNovelty(filterId)">
        <li :key="filterId" :class="itemClass" data-cy="mixed-grid-filters-selected-choice">
          <mixed-grid-active-filter-tag
            :filter-id="filterId"
            :label="$t('Products.filters.novelties')"
            @click.stop="$emit('removeFilter', filterId)"
          />
        </li>
      </template>
      <template v-else-if="isRange(filterId)">
        <li :key="filterId" :class="itemClass" data-cy="mixed-grid-filters-selected-choice">
          <mixed-grid-active-filter-tag
            :filter-id="filterId"
            :label="getFilterById(filterId).label"
            @click.stop="$emit('removeFilter', filterId)"
          />
        </li>
      </template>
      <template v-else>
        <li
          v-for="value in groupedFilters"
          :key="value"
          :class="itemClass"
          data-cy="mixed-grid-filters-selected-choice"
        >
          <mixed-grid-active-filter-tag
            :filter-id="filterId"
            :label="getFilterItemFromIdAndValue(filters, filterId, value).label"
            @click.stop="$emit('removeFilter', filterId, value)"
          />
        </li>
      </template>
    </template>
    <li>
      <button class="btn btn--link btn--sm" data-cy="clear-filters-btn" @click="$emit('removeAllFilters')">
        <span class="btn__text">{{ $t('Button.resetAllFilters') }} ({{ activeFiltersCount }})</span>
      </button>
    </li>
  </ul>
</template>
