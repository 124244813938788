<script>
import MixedGridFiltersItemList from '@phoenix/mixins/components/mixedGrid/MixedGridFiltersItemList';

export default {
  mixins: [MixedGridFiltersItemList],

  methods: {
    getInputId(item) {
      return `mixed-grid-filter-${this.filter.id}-${item.value}`;
    },
  },
};
</script>

<template>
  <ul class="products-filters-panel__list" data-cy="mixed-grid-filter-list" :data-cy-filter-id="filter.id">
    <li v-for="item in filter.items" :key="item.value">
      <div class="switch">
        <input
          :id="getInputId(item)"
          :data-property="filter.id"
          :data-value="item.value"
          :disabled="item.disabled && item.disabled === true"
          :checked="itemIsActive(item)"
          type="checkbox"
          :name="getInputId(item)"
          :value="item.value"
          class="switch__input"
          @change="$emit('onItemSelected', filter.id, item.value)"
        />
        <label :for="getInputId(item)" class="input-label switch__label checkbox-label">{{ item.label }}</label>
      </div>
    </li>
  </ul>
</template>
