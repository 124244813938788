<script>
import { PhxIcon } from '@phx-styleguides/als';

export default {
  components: { PhxIcon },

  props: {
    filterId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
<template>
  <button type="button" class="btn btn--icon btn--sm">
    <span class="btn__text">{{ label }}</span>
    <span class="btn__icon">
      <phx-icon icon="close" class="icon--sm" :title="$t('Button.remove')" />
    </span>
  </button>
</template>
