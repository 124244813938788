import MixedGridMediaItem from '@phoenix/mixins/components/mixedGrid/MixedGridMediaItem';

export default {
  mixins: [MixedGridMediaItem],

  props: {
    cta: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasCta() {
      return this.cta.hasOwnProperty('target');
    },
    hasTitle() {
      return this.title !== '';
    },
  },
};
