<script>
import MixedGridPagePushItem from '@phoenix/mixins/components/mixedGrid/MixedGridPagePushItem';
import CTA from '@maison/components/common/CTA';
import PictureLazy from '@maison/components/common/PictureLazy';

export default {
  components: {
    CTA,
    PictureLazy,
  },

  mixins: [MixedGridPagePushItem],

  props: {
    image: {
      type: Object,
      default: null,
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasImage() {
      return this.image !== null;
    },

    hasSubtitle() {
      return this.subtitle !== '';
    },

    hasDescription() {
      return this.description !== '';
    },

    itemClasses() {
      return ['page-push-card page-push-card--responsive-fluid'].concat(this.displayClasses);
    },
  },
};
</script>

<template>
  <div :class="itemClasses">
    <div class="page-push-card page-push-card--responsive-fluid" data-cy="mixedgrid-page-push">
      <template v-if="hasImage">
        <div class="picture picture--cover page-push-card__picture page-push-card__picture--square">
          <picture-lazy v-bind="image" />
        </div>
      </template>
      <div class="page-push-card__content">
        <div class="page-push-card__content-inner">
          <div v-if="hasSubtitle" class="page-push-card__subtitle heading">{{ subtitle }}</div>
          <div v-if="hasTitle" class="page-push-card__title heading">{{ title }}</div>
          <div v-if="hasDescription" class="page-push-card__text">{{ description }}</div>
        </div>
        <div class="page-push-card__actions grid grid-cols-1 gap-sm">
          <div>
            <c-t-a
              v-bind="cta"
              :classes="['btn btn--link page-push-card__btn expand-target']"
              icon="chevron-right"
              :icon-bidirectional="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
